import './App.css';
import SearchBar from './Components/SearchBar';
import FishingForecast from './Pages/FishingForecast';
import React from 'react';
function App() {
  return (
    <>
    
    {<FishingForecast/>}
    </>
  )
  }

export default App;
